const routes = [
    {
        path:"/",
        name: '/',
        component:()=>import ('@/layout/LayoutPage.vue'),
        redirect: { name: 'home' },
        children: [{
            path: '/',
            name:'home',
            component:()=>import ('@/pages/index/HomePage.vue')
        },{
            path: 'index/task',
            name:'form',
            component:()=>import ('@/pages/index/TaskPage.vue')
        },{
            path: 'index/form',
            name:'form',
            component:()=>import ('@/pages/index/FormPages.vue')
        },{
            path: 'index/table',
            name:'table',
            component:()=>import ('@/pages/index/TablePage.vue')
        }, {
            path: 'index/report',
            name:'report',
            component:()=>import ('@/pages/index/ReportPage.vue')
        },{
            path: 'setting/app',
            name:'app',
            component:()=>import ('@/pages/setting/AppPage.vue')
        },{
            path: 'setting/appitem',
            name:'appitem',
            component:()=>import ('@/pages/setting/AppItemPage.vue')
        },{
            path: 'setting/auth',
            name: 'auth',
            component: () => import('@/pages/setting/AuthPage.vue')
        },{
            path: 'setting/page',
            name: 'page',
            component: () => import('@/pages/setting/PagePage.vue')
        },{
            path: 'setting/workflow',
            name: 'workflow',
            component: () => import('@/pages/setting/WorkflowPage.vue')
        },{
            path: 'setting/print',
            name: 'print',
            component: () => import('@/pages/setting/PrintPage.vue')
        },{
            path: 'setting/system',
            name: 'system',
            component: () => import('@/pages/setting/SystemPage.vue')
        },{
            path: 'project/info',
            name: 'info',
            component: () => import('@/pages/project/InfoPages.vue')
        },{
            path: 'project/statistic',
            name: 'statistic',
            component: () => import('@/pages/project/StatisticPage.vue')
        }]
    }, {
        path:"/mobile",
        name: 'mobile',
        component: () => import('@/layout/MobileLayout.vue'),
        children: [{
            path: 'login',
            name:'login',
            component:()=>import ('@/pages/mobile/LoginPage.vue')
        },{
            path: 'approvedetail',
            name:'approvedetail',
            component:()=>import ('@/pages/mobile/ApproveDetail.vue')
        },{
            path: 'approvelist',
            name:'approvelist',
            component:()=>import ('@/pages/mobile/ApproveList.vue')
        }]
    }
]
export default routes