// 首页模块
import request from '@/utils/request.js'
// 获取顶部按钮信息
export const getTopBtnList = (params) => { //{pagePath:'',pageTag:'',pageId:''}
    return request({
        url: '/api/custom/get_page_button',
        method: 'post',
        data:params
    })
}
// 获取欢迎页信息
export const getPageData=(params)=>{
    return request({
        url: '/api/system/welcome',
        method: 'get',
        params
    })
}
// 获取常用功能信息
export const getOffenList = (params) =>{
    return request({
        url: '/api/system/websiteList',
        method: 'get',
        params
    })
}
// 修改常用功能
export const postOffenList = (params)=>{
    return request({
        url: '/api/system/setWebsiteList',
        method: 'post',
        data:params
    })
}