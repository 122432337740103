<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState("mobile", ["isMobile"]),
  },
  mounted() {
    let that = this;
    Vue.prototype.checkCallSuccess = function checkCallSuccess(
      param,
      callback
    ) {
      let res = param.res;
      let type = param.type;
      let result = false;
      if (res.errCode === 0) {
        if (type === "submit") {
          if (res.goToPage) {
            that.goToPageAction(res.goToPage);
          } else {
            if (res.msg && res.msg != "") {
              that.goToPageAction({
                type: 8,
                data: { type: "success", msg: res.msg },
              });
            }
          }
        }
        result = true;
      } else if (res.errCode === 1) {
        result = true;
      } else if (res.errCode === 404) {
        if (that.isMobile) {
          // this.$router.push("/mobile/login");
        } else {
          window.location.href = `${process.env.VUE_APP_USER_URL}/login.html`;
        }
      } else {
        if (res.goToPage) {
          that.goToPageAction(res.goToPage);
        } else if (res.msg) {
          that.goToPageAction({
            type: 8,
            data: { type: "error", msg: res.msg },
          });
        }
        result = false;
      }
      if (callback) {
        callback(result);
      }
      return result;
    };
  },
  methods: {
    ...mapActions("user", ["goToPageAction"]),
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
