import Vue from 'vue'
import App from './App.vue'
import router from '../src/router/index.js'
import store from './store/index.js';
import '@/styles/index.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import '@/assets/iconfont/iconfont.js'
import '@/assets/iconfont/iconfont.css'
import '@/directives/table-sticky.js'
import '@/directives/dialogdrag.js'
import '@/directives/resize.js'
import * as echarts from 'echarts'
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
new Vue({
    router,
    store,
  render: h => h(App),
}).$mount('#app')

 

String.prototype.trim = function (char, type) {
    if (char) {
      if (type == 'left') {
        return this.replace(new RegExp('^\\'+char+'+', 'g'), '')
      } else if (type == 'right') {
        return this.replace(new RegExp('\\'+char+'+$', 'g'), '')
      }
      return this.replace(new RegExp('^\\'+char+'+|\\'+char+'+$', 'g'), '')
    }
    return this.replace(/^\s+|\s+$/g, '')
}
Vue.prototype.echarts = echarts;
Vue.prototype.erpNotify = function (data){
  this.$notify({
    title: data.title?data.title:'系统提示', 
    dangerouslyUseHTMLString: true,
    message: data.content,
    duration: data.duration?data.duration:0,
    iconClass: data.iconClass?data.iconClass:'iconfont icontishi',
    customClass: 'erp-system-notify shake '+ (data.customClass?data.customClass:''),
    onClick: ''
  });
}