export default () => {
    return {
        newProjectDialog:{
            show: false,
            res:{},
            projectId: 0,
            title: "",
            header: {
                title: "项目管理",
                btnList: {list: [[{text:"关闭", size:"small", style:"width:70px", clickEvent:{type: 10, data:{function:"closeNewProjectDialog"}}}]]}
            },
            tabs:{
                show: [
                    {id: 1, label: "项目信息"},
                    {id: 2, label: "模块信息"},
                    {id: 3, label: "成员与权限"}
                ],
                activeId:1
            },
            form:{
                ref:"projectform",
                formProp: "newProjectDialog",
                pageTag: "project_insert",
                fields:[],
                value:{},
                btnList:{list:[],align:"center"}
            },
            moduleList:[],
            roleList: [],
            selectedRoleId: 1,
            roleForm:{
                show: false,
                form: {
                    title: "",
                    desc: "",
                    sortId: 100
                },
                roleId:0
            },
            main: {
                title: "",
                type: "",
                rows: []
            },
            tableReloader:0,
            addForm:{
                show: false,
                btn1: "",
                id: 0,
                pageId: 0,
                form:{
                    ref:"detailAddForm",
                    formProp: "addForm",
                    title: '',
                    gutter: 0,
                    type:'',
                    fields:[],
                    btnList:{list:[]}
                },
                btnListLeft:{list: []},
                btnListRight:{list: []},
            },
            info: {
                rows: []
            },
            hasSettingTable:false
        },
    }
}