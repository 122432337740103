import Vue from 'vue'
Vue.directive('resize', {
    bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
        let width = '', height = '';
        function isResize() {
        const style = document.defaultView.getComputedStyle(el);
        if (width !== style.width || height !== style.height) {
            binding.value({width:style.width,height:style.height});  // 关键(这传入的是函数,所以执行此函数)
        }
        width = style.width;
        height = style.height;
        }
        el.__vueSetInterval__ = setInterval(isResize, 300);
    },
    unbind(el) {
        clearInterval(el.__vueSetInterval__);
    }
})