import axios from 'axios';
import { Message } from 'element-ui'
import store from '@/store';
import router from '@/router';
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
const request = axios.create()
request.interceptors.request.use(
    config => {
        return config
    },
    error => {
        console.log(error)
        Promise.reject(error)
    }
)
request.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return response.data
        } else {
            Message({
                message: '请求失败',
                type: 'error',
                duration: 5 * 1000,
            })
        }
    },
    error => {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(error)
    }
  )
export default request