import Vue from 'vue';
import Vuex from 'vuex';
import user from './module/user';
import task from './module/task'
import home from './module/home'
import system from './module/system'
import table from './module/table'
import mobile from './module/mobile'
import project from './module/project'
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        user,task,home,system,table,mobile,project
    }
});
export default store