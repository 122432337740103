export default {
    changeUserInfo(state, params) {
        state.user.avatar=params.avatar
        state.user.name = params.name
        state.user.desc = params.desc
        state.user.btnList = params.btnList
        state.user.userMenuList = params.userMenuList
        state.user.id=params.id
    },
    changeIsCollapse(state) {
        if (!state.isCollapse) {
          state.menuIcon = "el-icon-s-unfold";
          state.subMenuWidth = 0;
        } else {
          state.menuIcon = "el-icon-s-fold";
          state.subMenuWidth = 200;
        }
        state.isCollapse = !state.isCollapse;
    },
    changeTopBtnList(state,params) {
        state.top.btnList.list=params.top.btnList.list
    },
    changeNowIdAndNowSubId(state,params) {
        state.NOW_ID = params.NOW_ID
        state.NOW_SUB_ID=params.NOW_SUB_ID
    },
    clearTopBtn(state) {
        state.top={
            btnList: { list: [], align: "right" },
        }
    },
    changeWebsocketUrl(state,param) {
        state.WEBSOCKET_URL=param
    },
    changeIsReload(state,param) {
        state.isReload=param
    },
    initPageSetting(state) {
        state.pageSetting = {
            title: "",
            subtitle: "页面自定义",
            pageTab: "页面配置",
            paramTab: "参数管理",
            indexTitle: "序号",
            nameTitle: "参数名",
            operationTitle: "操作",
            leaveConfirmMsg: "有未保存的信息没有保存，确认关闭吗？",
            show: false,
            btnList: {
                list: [
                    [
                        {
                            text: "确认保存",
                            type: "primary",
                            size: "small",
                            clickEvent: {
                                type: 10,
                                data: { function: "submitFormSetting" },
                            },
                        },
                    ],
                ],
                align: "right",
            },
            type: "account",
            isApprove: true,
            stepId: 1,
            appId: 0,
            approveConfigId: 0,
            pageSettingForm: {
                fields: [],
                btnList: { list: [] },
            },
            paramSettingForm: {
                btnName: "编辑",
                form: {
                    title: "",
                    selected: "",
                    isCustomize: true,
                    draggable: true,
                    hover: true,
                    select: true,
                    gutter: 20,
                    type: "",
                    fields: [],
                    btnList: {
                        list: [
                            [
                                {
                                    text: "确认",
                                    type: "primary",
                                    clickEvent: {
                                        type: 8,
                                        data: {
                                            type: "warning",
                                            msg: "当前页面仅供页面配置使用",
                                        },
                                    },
                                },
                            ],
                        ],
                    },
                },
                formCopy: {
                    title: "",
                    selected: "",
                    isCustomize: true,
                    draggable: true,
                    hover: true,
                    select: true,
                    gutter: 20,
                    type: "",
                    fields: [],
                    btnList: {
                        list: [
                            [
                                {
                                    text: "确认",
                                    type: "primary",
                                    clickEvent: {
                                        type: 8,
                                        data: {
                                            type: "warning",
                                            msg: "当前页面仅供页面配置使用",
                                        },
                                    },
                                },
                            ],
                        ],
                    },
                },
            },
            paramSettingFormOld: {
                form: {
                    title: "客户管理",
                    gutter: 20,
                    type: "",
                    fields: [],
                },
            },
            paramSettingFormParent: {
                form: {
                    title: "客户管理",
                    gutter: 20,
                    type: "",
                    fields: [],
                },
            },
            propSettingForm: {
                title: "属性管理",
                value: {},
                fields: [],
                btnList: { list: [] },
            },
            flowSetting: {
                cascaderId: "",
                tree: [],
                isLoadTree: false,
                submitType: "",
                importFlowId: 0,
                importType: "",
            },
            advancedSetting: {
                menu: [
                    { id: 1, title: "页面高级设置", icon: "el-icon-tickets" },
                    { id: 2, title: "流程高级设置", icon: "el-icon-cpu" },
                    { id: 3, title: "权限设置", icon: "el-icon-lock" },
                    { id: 4, title: "打印设置", icon: "el-icon-monitor" },
                    { id: 5, title: "通知设置", icon: "el-icon-monitor" }
                ],
                setting: [
                    {
                        title: "自动去重",
                        icon: "el-icon-circle-check",
                        desc: "审批流程中审批人重复出现时，只需审批一次其余自动通过",
                        prop: "is_distinct",
                        checked: false,
                    },
                    {
                        title: "允许撤销",
                        icon: "el-icon-circle-check",
                        desc: "开启后发起人可以对自己发起的审批进行撤销操作",
                        prop: "is_cancel",
                        checked: false,
                    },
                    {
                        title: "审批意见必填",
                        icon: "el-icon-circle-check",
                        desc: "设置审批功能是否必填",
                        prop: "is_must",
                        checked: false,
                    },
                    {
                        title: "自动自审批",
                        icon: "el-icon-circle-check",
                        desc: "自动通过自己发起的审批项",
                        prop: "is_own",
                        checked: false,
                    },
                    {
                        title: "是否启用",
                        icon: "el-icon-circle-check",
                        desc: "启用当前流程",
                        prop: "is_enable",
                        checked: true,
                    },
                ],
                currentMenu: 2,
            },
        }
    }
}