// 用户模块
import request from '@/utils/request.js'
// 获取用户信息
export const getUserInfo = (params) =>{
    return request({
        url: '/api/account/user',
        method: 'get',
        params
    })
}
// 获取公司信息
export const getCompanyInfo = (params) => {
    return request({
        url: '/api/account/info',
        method: 'get',
        params
    })
}
// 切换公司
export const switchCompany = (params) => {
    return request({
        url: '/api/company/switch',
        method: 'get',
        params
    })
}
// 获取菜单信息
export const getMenuList = (params) => {
    return request({
        url: '/api/system/MenuList',
        method: 'get',
        params
    })
}