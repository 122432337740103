export default()=>{
    return {
        mobileHeader:{
            goBack: true,
            showMobileHeader: true,
            title: 'ERP',
            goBackUrl: ''
        },
        showEmpty: false,
        changeCompanyModal:{
            show: false,
            companyList: [],
            form: {
                merchant_id: 0
            }
        },
        isMobile:false
    }
}