export default () => {
    return {
        isReload:false,
        user: { avatar: '', name: '', desc: '', btnList: { list: [] }, userMenuList: [],id: 0 },
        company: {
            merchantId: 0, name: "", desc: "", btnList: { list: [] }, logo: "", list: [], bind_wx: {
                corpid:'',
                work_user_wx: '',
                work_wx_bind_info_url:''
                
        } },
        menuList: [],
        menu: {
            title: "",
            icon: "",
            type: "",
            title2: "",
            icon2: "",
            iconColor2: "",
          },
        changeCompanyModal:{
            show: false,
            companyList: [],
            form: {
                id: 0,
                account_id: 0
            }
        },
        accountId: '',
        merchantId: '',
        NOW_ID: "-1",
        NOW_SUB_ID: "-2",
        subMenu: [],
        subMenuCustom: [],
        isCollapse: false,
        menuIcon: "el-icon-s-fold",
        subMenuWidth: 200,
        top: {
            btnList: { list: [], align: "right" },
        },
        drawerWidth: 0,
        workflowIframeHeight: 0,
        helpCenter: {
            show: false,
            title: "快捷帮助",
            content: [
              {
                title: "功能说明",
                body: "君子总是从善良的或有利于他人的愿望出发，全心全意促使别人实现良好的意愿和正当的要求，不会用冷酷的眼光看世界。或是唯恐天下不乱，不会在别人有失败、错误或痛苦时推波助澜。小人却相反，总是“成人之恶，不成人之美",
              },
              {
                title: "常见问题",
                body: [
                  { text: "1. 天行健，君子以自强不息。" },
                  { text: "2. 勿以恶小而为之，勿以善小而不为。" },
                  { text: "3. 见善如不及，见不善如探汤。" },
                  { text: "4. 躬自厚而薄责于人，则远怨矣。" },
                  { text: "5. 君子成人之美，不成人之恶。小人反是。" },
                  { text: "查看更多 >>", style: "color:#409EFF;margin-left:20px" },
                ],
              },
              {
                title: "联系方式",
                body: [
                  { text: "电话: 400-888-999" },
                  { text: "邮箱: info@mrooa.com" },
                ],
                btnList: {
                  list: [
                    [
                      {
                        text: "在线客服",
                        type: "primary",
                        plain: true,
                        style: "margin-left:20px",
                      },
                    ],
                  ],
                },
              },
            ],
        },
        roleModal: {
            title: "",
            btnText: "",
            show: false,
            form: {
              id: 0,
              groupId: "",
              title: "",
              desc: "",
              sort: 100,
              status: 1,
            },
        },
        roleGroupModal: {
            title: "",
            btnText: "",
            show: false,
            form: {
              id: 0,
              title: "",
              desc: "",
              sort: 100,
              status: 1,
            },
        },
        WEBSOCKET_URL: ''
    }
}