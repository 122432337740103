import { getTopBtnList } from "@/apis/home.js";
import { getUserInfo, getCompanyInfo, getMenuList } from "@/apis/user.js";
import router from "@/router/index.js";
import ElementUI from "element-ui";
import { getClearMerchant, getClearAccount, postBindClientId } from "@/apis/system";
export default {
	async getFetchUserInfo(context, params) {
		let result = await getUserInfo(params);
		context.commit("changeUserInfo", {
			avatar: result.data.avatar,
			name: result.data.name || "",
			desc: result.data.desc || "",
			btnList: result.data.btnList || [],
			userMenuList: result.data.userMenuList || [],
			id: result.data.id || 0
		});
		context.dispatch("getFetchCompanyInfo");
	},

	async getFetchMenuList(context, params) {
		let result = await getMenuList(params);
		let menu = [];
		for (let i in result.data) {
			let menuItem = {};
			menuItem.id = result.data[i].id;
			menuItem.name = result.data[i].title;
			menuItem.path = result.data[i].page_path;
			menuItem.pid = result.data[i].parent_id;
			menuItem.icon = result.data[i].icon ? result.data[i].icon : "iconfont iconhome";
			menuItem.iconColor = result.data[i].icon_color ? result.data[i].icon_color : "";
			menuItem.iconNav = result.data[i].icon_nav ? result.data[i].icon_nav : "iconfont iconhome";
			menuItem.type = result.data[i].type ? result.data[i].type : "";
			menuItem.showPop = result.data[i].showPoP ? result.data[i].showPoP : false;
			menuItem.pop = result.data[i].pop ? result.data[i].pop : "";
			if (menuItem.pop >= 100) {
				menuItem.pop = "99+";
			} else {
				menuItem.pop = menuItem.pop + "";
			}
			menuItem.child = [];
			if (result.data[i].child) {
				for (let j in result.data[i].child) {
					let childItem = {};
					if (result.data[i].type === "message" || result.data[i].type === "project") {
						childItem.id = "m_" + result.data[i].child[j].id;
					} else {
						childItem.id = "n_" + result.data[i].child[j].id;
					}
					childItem.name = result.data[i].child[j].title;
					childItem.desc = result.data[i].child[j].desc ? result.data[i].child[j].desc : "";
					childItem.path = result.data[i].child[j].page_path;
					childItem.pid = result.data[i].child[j].parent_id;
					childItem.image = result.data[i].child[j].pic;
					childItem.icon = result.data[i].child[j].icon ? result.data[i].child[j].icon : "iconfont iconhome";
					childItem.iconColor = result.data[i].child[j].icon_color;
					childItem.showPop = result.data[i].child[j].showPoP ? result.data[i].child[j].showPoP : false;
					childItem.pop = result.data[i].child[j].pop ? result.data[i].child[j].pop : "";
					if (childItem.pop >= 100) {
						childItem.pop = "99+";
					} else {
						childItem.pop = childItem.pop + "";
					}
					menuItem.child.push(childItem);
				}
			}
			menuItem.customChild = [];
			if (result.data[i].custom_child) {
				for (let j in result.data[i].custom_child) {
					let childItem = {};
					if (result.data[i].type === "project") {
						childItem.id = "p_" + result.data[i].custom_child[j].id;
					}
					childItem.name = result.data[i].custom_child[j].short_title
						? result.data[i].custom_child[j].short_title
						: result.data[i].custom_child[j].title;
					childItem.desc = result.data[i].custom_child[j].desc ? result.data[i].custom_child[j].desc : "";
					childItem.path = result.data[i].custom_child[j].page_path;
					childItem.pid = result.data[i].custom_child[j].parent_id;
					childItem.image = result.data[i].custom_child[j].pic;
					childItem.icon = result.data[i].custom_child[j].icon
						? result.data[i].custom_child[j].icon
						: "iconfont iconhome";
					childItem.iconColor = result.data[i].custom_child[j].icon_color;
					childItem.showPop = result.data[i].custom_child[j].showPoP
						? result.data[i].custom_child[j].showPoP
						: false;
					childItem.pop = result.data[i].custom_child[j].pop ? result.data[i].custom_child[j].pop : "";
					childItem.status = result.data[i].custom_child[j].status;
					menuItem.customChild.push(childItem);
				}
			}
			menu.push(menuItem);
		}
		context.state.menuList = menu;
		if (context.state.menuList.length > 0) {
			context.dispatch("showSub");
		}
	},
	async getFetchCompanyInfo(context, params) {
		let result = await getCompanyInfo(params);
		context.rootState.mobile.changeCompanyModal.form.merchant_id = result.data.merchant_id;
		context.state.accountId = result.data.id;
		context.state.merchantId = result.data.merchant_id;
		context.state.user.desc = result.data.companysection ? result.data.companysection.title : "";
		context.state.user.name = result.data.real_name || "";
		if (result.data.merchant) {
			context.state.company.merchantId = result.data.merchant_id;
			context.state.company.name = result.data.merchant.title;
			context.state.company.desc = result.data.merchant.desc;
			context.state.company.logo = result.data.merchant.logo;
			context.state.company.btnList = {
				list: [
					[
						{
							text: "续费",
							size: "mini",
							type: "success",
							plain: true,
							style: "padding:5px 6px;"
						}
					]
				]
			};
			context.state.company.list = [];
			context.state.company.bind_wx.corpid = result.data.merchant.corpid;
			if (result.data.merchant.corpid) {
				context.state.company.bind_wx.work_user_wx = result.data.work_user_wx;
				context.state.company.bind_wx.work_wx_bind_info_url = result.data.work_wx_bind_info?.url;
			}
			for (let i = 0; i < result.data.merchant_list?.length; i++) {
				context.state.company.list.push({
					title: result.data.merchant_list[i].title,
					merchantId: result.data.merchant_list[i].id,
					accountId: result.data.merchant_list[i].account_id,
					erp_status: result.data.merchant_list[i].erp_status
				});
			}
		}
	},
	async getFetchTopBtnList(context, params) {
		let result = await getTopBtnList(params);
		context.state.top.btnList.list = [];
		if (result.data.btnListTop && result.data.btnListTop.length > 0) {
			context.state.top.btnList.list = result.data.btnListTop;
		}
	},
	showSub(context, item) {
		context.state.subMenu = [];
		context.state.subMenuCustom = [];
		if (!item) {
			context.state.menuList.forEach((item1, index1) => {
				if (item1.child) {
					item1.child.forEach((item2, index2) => {
						if (router.currentRoute.fullPath == item2.path) {
							context.state.subMenu = context.state.menuList[index1].child;
							context.state.subMenuCustom = context.state.menuList[index1].customChild;
							context.state.NOW_ID = context.state.menuList[index1].id;
							context.state.NOW_SUB_ID = context.state.menuList[index1].child[index2].id;
							context.state.menu.title = context.state.menuList[index1].name;
							context.state.menu.icon = context.state.menuList[index1].icon;
							context.state.menu.iconColor = context.state.menuList[index1].iconColor;
							context.state.menu.type = context.state.menuList[index1].type;
						}
					});
				}
				if (item1.customChild) {
					item1.customChild.forEach((item2, index2) => {
						if (router.currentRoute.fullPath == item2.path) {
							context.state.subMenu = context.state.menuList[index1].child;
							context.state.subMenuCustom = context.state.menuList[index1].customChild;
							context.state.NOW_ID = context.state.menuList[index1].id;
							context.state.NOW_SUB_ID = context.state.menuList[index1].customChild[index2].id;
							context.state.menu.title = context.state.menuList[index1].name;
							context.state.menu.icon = context.state.menuList[index1].icon;
							context.state.menu.iconColor = context.state.menuList[index1].iconColor;
							context.state.menu.type = context.state.menuList[index1].type;
						}
					});
				}
			});
		} else {
			context.state.subMenu = item.child;
			context.state.subMenuCustom = item.customChild;
			context.state.NOW_ID = item.id;
			if (item.child) {
				item.child.forEach((item2, index) => {
					if (item2.path == item.path) {
						context.state.NOW_SUB_ID = item.child[index].id;
					}
				});
			}
			if (item.customChild) {
				item.customChild.forEach((item2, index) => {
					if (item2.path == item.path) {
						context.state.NOW_SUB_ID = item.customChild[index].id;
					}
				});
			}
			context.state.menu.title = item.name;
			context.state.menu.icon = item.icon;
			context.state.menu.iconColor = item.iconColor;
			context.state.menu.type = item.type;
		}
	},
	goToPageAction(context, e, param) {
		if (e && e?.type) {
			switch (e.type) {
				case 1: // 跳转页面，data.url = "https:www.baidu.com"
					window.location.href = e.data.url;
					break;
				case 2: // 打开新页面，data.url = "https:www.baidu.com"
					window.open(e.data.url);
					break;
				case 3: // 重新请求数据
					// this.getList()
					break;
				case 4: // 异步请求
					// this.asyncCall(e.data)
					break;
				case 5: // 弹窗
					// this.dialog.visible = true
					// this.dialog.title = e.data.title?e.data.title:"系统信息"
					// this.dialog.msg = e.data.msg?e.data.msg:"系统发送了一条空白的信息内容"
					// this.dialog.btnList = e.data.btnList
					// if (e.data && e.data._rowData && e.data.btnList && e.data.btnList.list && e.data.btnList.list.length > 0){
					//     for (var i =0;i < this.dialog.btnList.list.length;i++){
					//         for (let j=0;j < this.dialog.btnList.list[i].length; j++){
					//             if (this.dialog.btnList.list[i][j].clickEvent && this.dialog.btnList.list[i][j].clickEvent.data){
					//                 this.dialog.btnList.list[i][j].clickEvent.data._rowData = e.data._rowData
					//             }
					//             if (!this.dialog.btnList.list[i][j].clickEvent){
					//                 this.dialog.btnList.list[i][j].clickEvent = {_local_function: '_close_dialog'}
					//             }
					//         }
					//     }
					// }
					break;
				case 6: // 通过url下载文件到本地
					// this.download(e.data.url, e.data.filename)
					break;
				case 7: // 上传文件，具体实现会调用上传组件，必须作用于按钮上
					break;
				case 8: // 提示框
					if (e.data.type === "success") {
						ElementUI.Message.success(e.data.msg);
					} else if (e.data.type === "warning") {
						ElementUI.Message.warning(e.data.msg);
					} else if (e.data.type === "error") {
						ElementUI.Message.error(e.data.msg);
					}
					break;
				case 9: // 下拉选择，组件内实现
					break;
				case 10: // 执行特定方法
					if (e.data && e.data.function === "search") {
						// 表格搜索
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "helpCenter") {
						// 帮助中心
						context.dispatch("openHelpDrawer");
					} else if (e.data && e.data.function === "settingCenter") {
						// 打开表单配置中心
						this.openSettingCenter(param);
					} else if (e.data && e.data.function === "tableSettingCenter") {
						// 打开表格配置中心
						console.log(e, param);
						// this.$refs.tablepage.$refs.settingtablepage.openTableSettingCenter(param)
					} else if (e.data && e.data.function === "submitTableSetting") {
						// 提交表格配置
						this.submitTableSetting();
					} else if (e.data && e.data.function === "submitFormSetting") {
						// 提交表单配置
						this.submitFormSetting();
					} else if (e.data && e.data.function === "subscribeCenter") {
						// 订阅中心
						// context.dispatch('openSubscribeCenter')
					} else if (e.data && e.data.function === "getSummary") {
						// 获取表格统计
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "print") {
						// 打印筛选下拉
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "addForm") {
						// 新增表单弹窗
						// formpage中预定，此处为空
					} else if (e.data && e.data.function === "editForm") {
						// 表格行级别编辑
						// formpage中预定，此处为空
					} else if (e.data && e.data.function === "doSubmitData") {
						// 新增/编辑表单数据
						// this.valueCheckFail=this.$refs.formpage.valueCheckFn();
						// if(this.valueCheckFail.length>0){
						//     this.$message.error('请填写必填项');
						//     return
						// }
						// this.$refs.formpage.doSubmitData().then((res)=>{
						//     if(res){
						//         this.addForm.show=false
						//         this.getMsgList()
						//         return
						//     }
						// })
						//this.doSubmitData()
					} else if (e.data && e.data.function === "doSubmitDataDetail") {
						// 详情页中新增/编辑表单数据
						//this.doSubmitDataDetail()
					} else if (e.data && e.data.function === "closeAddForm") {
						// 关闭新增/编辑表单
						this.addForm.show = false;
					} else if (e.data && e.data.function === "doDeleteData") {
						// 表格行级别删除
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "doBatchDeleteData") {
						// 数据批量删除
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "batchEdit") {
						// 数据批量编辑
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "closeBatchForm") {
						// 关闭批量编辑弹层
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "openSort") {
						// 打开排序弹窗
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "changeHeadAccountId") {
						// 数据转入编辑
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "closeBatchFormHeadAccount") {
						// 关闭数据转入弹层
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "pickInquiry") {
						// 选定询价
						// tablepage 中预定，此处为空
					} else if (e.data && e.data.function === "showTableFields") {
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "openUserModal") {
						// 打开成员角色选择器
						// 组件中预定，此处为空
					} else if (e.data && e.data.function === "import") {
						// 导入
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "export") {
						// 导出
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "resetForm") {
						//重置表单
						// 按钮中预定，此处为空
					} else if (e.data && e.data.function === "openDetail") {
						// 打开详情
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "closeDetail") {
						// 关闭详情
						this.closeDetail();
					} else if (e.data && e.data.function === "saveProject") {
						// 新增编辑项目
						this.saveProject();
					} else if (e.data && e.data.function === "systemSetting") {
						// 提交系统配置
						//this.saveSystemSetting()
					} else if (e.data && e.data.function === "approveFlowCancel") {
						// 审批撤销
						// infopage内定，此处为空
					} else if (e.data && e.data.function === "approveFlowSuccess") {
						// 审批通过
						// infopage内定，此处为空
					} else if (e.data && e.data.function === "approveFlowReject") {
						// 审批否决
						// infopage内定，此处为空
					} else if (e.data && e.data.function === "detailOpenDetail") {
						// 详情页打开详情页
						// infopage内定，此处为空
					} else if (e.data && e.data.function === "approveUpdateOpenDetail") {
						// 详情页打开详情页
						// infopage内定，此处为空
					} else if (e.data && e.data.function === "openNewRoleModal") {
						// 角色弹窗 角色分组弹窗
						context.dispatch("openNewRoleModal");
					} else if (e.data && e.data.function === "openEditRoleModal") {
						this.openEditRoleModal();
					} else if (e.data && e.data.function === "openNewRoleGroupModal") {
						context.dispatch("openNewRoleGroupModal");
					} else if (e.data && e.data.function === "openEditRoleGroupModal") {
						this.openEditRoleGroupModal();
					} else if (e.data && e.data.function === "addRoleMember") {
						// this.addRoleMember(param.selectedUser)
					} else if (e.data && e.data.function === "removeRoleMember") {
						// this.removeRoleMember()
					} else if (e.data && e.data.function === "triggerDropDown") {
						// 按钮下拉点击事件
					} else if (e.data && e.data.function === "triggerPrintDropDown") {
						// 触发打印下拉点击事件
						if (param.value == "printTplSetting") {
							//打印模版设置
							this.openPrintTplSetting(param);
						} else if (param.value == "printPreview") {
							// 打印数据预览
							this.openPrintPreview(param);
						}
					} else if (e.data && e.data.function === "doBatchEdit") {
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "doBatchEditHeadAccount") {
						// tablepage中预定，此处为空
					} else if (e.data && e.data.function === "submitRoleMenu") {
						this.submitRoleMenu();
					} else if (e.data && e.data.function === "openProjectModal") {
						context.dispatch("openProjectModal", e, param);
					} else if (e.data && e.data.function === "saveWorkflow") {
						this.saveWorkflow();
					} else if (e.data && e.data.function === "saveWorkflowAdvanced") {
						this.saveWorkflowAdvanced();
					} else if (e.data && e.data.function === "reloadCacheAccount") {
						this.reloadCache("account");
					} else if (e.data && e.data.function === "submitMerchantConfig") {
						this.submitMerchantConfig();
					}
					break;
				case 11: // 清空当前表单，组件内实现
					break;
				case 12: // 清空缓存
					switch (e.data.type) {
						case "account":
							context.dispatch("getFetchClearAccount");
							break;
						case "merchant":
							context.dispatch("getFetchClearMerchant");
							break;
					}
					break;
				case 13: // 关闭所有multitable，组件内实现
					break;
			}
		}
		if (e && e._local_function) {
			if (e._local_function === "_close_dialog") {
				this.closeDialog();
			}
		}
	},
	getFetchDrawerWidth(context) {
		let width = window.innerWidth;
		context.state.drawerWidth = width - 260;
	},
	async getFetchClearMerchant(context) {
		await getClearMerchant();
	},
	async getFetchClearAccount(context) {
		await getClearAccount();
	},
	openNewRoleModal(context) {
		context.state.roleModal.title = "新建角色";
		context.state.roleModal.btnText = "确认新增";
		context.state.roleModal.form.id = 0;
		context.state.roleModal.form.sort = 100;
		context.state.roleModal.form.status = 1;
		context.state.roleModal.show = true;
	},
	openNewRoleGroupModal(context) {
		context.state.roleGroupModal.title = "新建角色组";
		context.state.roleGroupModal.btnText = "确认新增";
		context.state.roleGroupModal.form.id = 0;
		context.state.roleGroupModal.form.sort = 100;
		context.state.roleGroupModal.form.status = 1;
		context.state.roleGroupModal.show = true;
	},
	getWorkflowIframeHeight(conetxt) {
		let height = window.innerHeight;
		conetxt.state.workflowIframeHeight = height - 54;
	},
	reloadCache(context, type) {
		context.state.isReload = false;
		if (type == "account") {
			context.dispatch("getFetchClearMerchant");
			context.dispatch("getFetchClearAccount");
		} else if (type == "merchant") {
			context.dispatch("getFetchClearMerchant");
			context.dispatch("getFetchClearAccount");
		}
		context.dispatch("getFetchUserInfo");
		context.dispatch("getFetchMenuList");

		let accountId = context.state.accountId;
		let frameData = localStorage.getItem("frameData");
		let frameDataObj = {};
		if (frameData) {
			frameDataObj = JSON.parse(frameData);
			if (frameDataObj[accountId]) {
				delete frameDataObj[accountId];
				localStorage.setItem("frameData", JSON.stringify(frameDataObj));
			}
		}
	},
	openHelpDrawer(context) {
		context.state.helpCenter.show = true;
	},
	connectWebsocket(context) {
		let websocket;
		if (typeof WebSocket === "undefined") {
			console.log("您的浏览器不支持WebSocket");
			return;
		} else {
			let url = `${context.state.WEBSOCKET_URL}`;
			// 打开一个websocket
			websocket = new WebSocket(url);
			// 建立连接
			websocket.onopen = () => {
				// 发送数据
				websocket.send("发送数据");
				console.log("websocket发送数据中");
			};
			// 客户端接收服务端返回的数据
			websocket.onmessage = evt => {
				console.log("websocket返回的数据：", evt);
				if (evt.data) {
					let data = JSON.parse(evt.data);
					if (data.emit === "bind") {
						let clientId = data.data.client_id;
						context.dispatch("bindClientId", clientId);
						context.dispatch("socketHeartBeat", websocket);
					} else if (data.emit === "getMessage") {
						let msgType = data.data.type;
						if (msgType === "reload") {
							context.dispatch("getFetchMenuList");
						}
					}
				}
			};
			// 发生错误时
			websocket.onerror = evt => {
				console.log("websocket错误：", evt);
			};
			// 关闭连接
			websocket.onclose = evt => {
				console.log("websocket关闭：", evt);

				setTimeout(() => {
					context.dispatch("connectWebsocket");
				}, 30000);
			};
		}
	},
	async socketHeartBeat(context, websocket) {
		let t = setInterval(function () {
			try {
				if (websocket.readyState == 1) {
					websocket.send(
						JSON.stringify({
							emit: "ping",
							data: "Hi Server, I am LayIM! Ping! Ping! Ping!"
						})
					);
				} else {
					clearInterval(t);
				}
			} catch (e) {}
		}, 30000);
	},
	async bindClientId(context, clientId) {
		let req = { client_id: clientId };
		await postBindClientId(req);
	},
	openProjectModal(context, e, param) {
		context.rootState.project.newProjectDialog.hasSettingTable = false;
		context.rootState.project.newProjectDialog.show = true;
	}
};
