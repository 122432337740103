// 系统设置模块
import request from '@/utils/request.js'
// 系统设置
export const postSystemSetting = (params) => {
    return request({
        url: '/api/system/setting',
        method: 'post',
        data:params
    })
}
// 保存系统设置
export const putSaveSystemSetting = (params) => {
    return request({
        url: '/api/system/settingSave',
        method: 'put',
        data:params
    })
}
// 获取应用列表
export const getApplicationList = (params) => {
    return request({
        url: '/api/application/list',
        method: 'get',
        params
    })
}
// 开启应用
export const getOpenApplication = (params) => {
    return request({
        url: '/api/application/install',
        method: 'get',
        params
    })
}
// 获取应用信息
export const getApplicationInfo = (id,params) => {
    return request({
        url: `/restapi/application/${id}`,
        method: 'get',
        params
    })
}
// 编辑应用
export const putEditApplication = (id,params) => {
    return request({
        url: `/restapi/application/${id}`,
        method: 'put',
        data:params
    })
}
// 获取角色树
export const postPageTree = (params) => {
    return request({
        url: `/api/pageGroup/tree`,
        method: 'post',
        data:params
    })
}
// 获取校色分组树
export const postRoleTree = (params={get_members: 1}) => {
    return request({
        url: `/api/roleGroup/tree`,
        method: 'post',
        data:params
    })
}
// 删除角色或角色组
export const deleteRoleOrRoleGroup = (url,params) => {
    return request({
        url,
        method: 'delete',
        data:params
    })
}
// 复制角色
export const postCopyRole = (id,params) => {
    return request({
        url:  `/restapi/role/copy/${id}`,
        method: 'post',
        data:params
    })
}
// 新建角色
export const postAddRole = (params) => {
    return request({
        url:  `/restapi/role`,
        method: 'post',
        data:params
    })
}
// 编辑角色
export const putEditRole = (id,params) => {
    return request({
        url:  `/restapi/role/${id}`,
        method: 'put',
        data:params
    })
}
// 新建角色组
export const postAddRoleGroup = (params) => {
    return request({
        url:  `/restapi/roleGroup`,
        method: 'post',
        data:params
    })
}
// 编辑角色组
export const putEditRoleGroup = (id,params) => {
    return request({
        url:  `/restapi/roleGroup/${id}`,
        method: 'put',
        data:params
    })
}
// 切换角色菜单权限
export const getRoleMenu = (params) => {
    return request({
        url:  `/restapi/AccessMenu`,
        method: 'get',
        params
    })
}
// 切换角色功能权限
export const getRoleFunction = (id,params) => {
    return request({
        url:  `/restapi/AccessAction/${id}`,
        method: 'get',
        params
    })
}
// 切换校色数据权限
export const getRoleField = (id,params) => {
    return request({
        url:  `/restapi/AccessField/${id}`,
        method: 'get',
        params
    })
}
//切换校色模块权限
export const getRoleModule = (id,params) => {
    return request({
        url:  `/restapi/AccessModule/${id}`,
        method: 'get',
        params
    })
}
// 批量添加角色成员
export const postAddBatchRoleMember = (params) => {
    return request({
        url:  `/api/roleAccount/batchAdd`,
        method: 'post',
        data:params
    })
}
// 批量删除角色成员
export const deleteRemoveBatchRoleMember = (params) => {
    return request({
        url:  `/api/roleAccount/batchDelete`,
        method: 'delete',
        data:params
    })
}
// 修改角色菜单，功能，数据等权限
export const putEditSubmit = (url,params) => {
    return request({
        url,
        method: 'put',
        data:params
    })
}
// 获取商户所有模块
export const postPageMerchant = (url,params) => {
    return request({
        url:'/api/custom/get_page_merchant',
        method: 'post',
        data:params
    })
}
// 获取表格设置信息
export const postTableSetting = (params) => {
    return request({
        url:'/api/custom/get_table_set',
        method: 'post',
        data:params
    })
}
// 保存表格设置信息
export const postSaveTableSetting = (params) => {
    return request({
        url:'/api/custom/save_table_set',
        method: 'post',
        data:params
    })
}
// 清除账户缓存
export const getClearAccount = (params) => {
    return request({
        url:'/api/system/cacheClearAccount',
        method: 'get',
        params
    })
}
// 清除商户缓存
export const getClearMerchant = (params) => {
    return request({
        url:'/api/system/cacheClearMerchant',
        method: 'get',
        params
    })
}
// 获取表单信息设置信息
export const postFormSetting = (params) => {
    return request({
        url:'/api/custom/get_form_set',
        method: 'post',
        data:params
    })
}
// 保存表格设置信息
export const postSaveFormSetting = (params) => {
    return request({
        url:'/api/custom/save_form_set',
        method: 'post',
        data:params
    })
}
// 页面设置
export const putFlowAdvancedSetting = (params) => {
    return request({
        url:'/api/workflow/get_flow_advanced_setting',
        method: 'put',
        data:params
    })
}
// 获取系统信息
export const postAdvancedSetting = (params) => {
    return request({
        url:'/api/system/advancedSetting',
        method: 'post',
        data:params
    })
}
// 修改系统信息
export const putSaveAdvancedSetting = (params) => {
    return request({
        url:'/api/system/advancedSettingSave',
        method: 'put',
        data:params
    })
}
// 获取系统流程设置
export const postSystemWorkflow = (params) => {
    return request({
        url:'/api/workflow/get_system_form_flow',
        method: 'post',
        data:params
    })
}
// 获取全部订阅信息
export const getTaskAideAll = (params) => {
    return request({
        url:'/api/taskAide/all',
        method: 'post',
        data:params
    })
}
// 设置订阅信息
export const postSetTaskAide = (params) => {
    return request({
        url:'/api/taskAide/setList',
        method: 'post',
        data:params
    })
}
// 
export const postBindClientId = (params) => {
    return request({
        url:'/api/account/bind',
        method: 'post',
        data:params
    })
}
// 获取导入模板列表
export const postFlowTemplateList = (params) => {
    return request({
        url:'/api/workflow/get_import_flow_list',
        method: 'post',
        data:params
    })
}
// 获取模板列表
export const postFlowMarketTemplateList = (params) => {
    return request({
        url:'/api/workflow/get_template_list',
        method: 'post',
        data:params
    })
}
// 保存小数点位数设置
export const postSaveDecimalNumberConf = (params) => {
    return request({
        url: '/api/custom/save_decimal_number_conf',
        method: 'post',
        data:params
    })
}
//获取小数点位数设置
export const getDecimalNumberConf = (params) => {
    return request({
        url: '/api/custom/get_global_config',
        method: 'post',
        data:params
    })
}


//打印设置
//打印管理
export const postPagePrint = (params) => {
    return request({
        url: '/api/custom/get_page_print',
        method: 'post',
        data:params
    })
}
// 获取商户打印配置
export const postMerchantTemplatePrint = (params) => {
    return request({
        url: '/api/custom/get_merchant_template_print',
        method: 'post',
        data:params
    })
}
// 保存模板
export const postSaveMerchantTemplate = (params) => {
    return request({
        url: '/api/custom/save_merchant_template_print',
        method: 'post',
        data:params
    })
}
// 表格打印
export const postPrintTable = (params) => {
    return request({
        url: '/api/custom/get_print_table',
        method: 'post',
        data:params
    })
}
// 详情打印
export const postPrintInfo = (params) => {
    return request({
        url: '/api/custom/get_print_info',
        method: 'post',
        data:params
    })
}